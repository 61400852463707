<template>
  <Scaffold>
    <template #buttons>
      <Button @click="() => $router.back()">뒤로</Button>
      <Button type="primary" @click="onCreate">생성</Button>
    </template>
    <div class="page-content">
      <div class="result-box" v-if="exists(result)">
        <div class="success">{{ result.success }}개 티켓 생성됨.</div>
        <div class="error" v-if="exists(result.error)">
          <div class="label">생성 실패</div>
          <div class="error-item" v-for="e in result.error">{{ e }}</div>
        </div>
      </div>

      <Form label-position="right" :label-width="120">
        <FormItem label="휴대폰 번호">
          <Input type="textarea" :rows="5" placeholder="엔터로 구분" size="large" v-model="form.usernames"></Input>
        </FormItem>
        <FormItem label="클래스">
          <Select v-model="form.lecture_ids" multiple>
            <Option v-for="(lecture, index) in lectures" :key="`option-${index}`" :value="lecture.id">
              {{ lecture.title }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="수강 기간">
          <Row :gutter="16">
            <Col span="8">
              <CDatePicker size="large"
                           placeholder="시작일"
                           type="datetime"
                           v-model="form.start_at"
              ></CDatePicker>
            </Col>
            <Col span="8">
              <CDatePicker size="large"
                           placeholder="종료일"
                           type="datetime"
                           v-model="form.end_at"
              ></CDatePicker>
            </Col>
            <Col span="4">
              <div class="r-gap-buttons">
                <Button @click="() => editYear(1)">+1년</Button>
                <Button type="error" @click="() => editYear(-1)">-1년</Button>
              </div>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </div>
  </Scaffold>
</template>

<script>
export default {
  computed: {},
  data() {
    return {
      form: {
        usernames: '',
        lecture_ids: '',
        start_at: null,
        end_at: null,
      },
      lectures: [],
      result: {},
    }
  },
  methods: {
    editYear(val) {
      let date;
      if (this.form.end_at) {
        date = this.$moment(this.form.end_at)
      } else if (this.form.start_at) {
        date = this.$moment(this.form.start_at)
      } else {
        date = this.$moment()
      }
      date.add(val, 'years')
      this.form.end_at = date.format('YYYY-MM-DD HH:mm')
    },
    onCreate() {
      this.$axios.$post('ticket/bulk', this.form).then(res => {
        this.result = res.result
        this.form = {
          usernames: '',
          lecture_ids: '',
          start_at: null,
          end_at: null,
        }
      })
    },
    getData() {
      this.$axios.$get('lecture/simple').then(res => {
        this.lectures = res.result
      })
    }
  },
  mounted() {
    this.getData()
  }

}
</script>

<style lang="scss" scoped>
.result-box {
  padding: 20px;
  border: 1px solid black;
  margin-bottom: 24px;

  .error {
    margin-top: 12px;

    .label {
      color: indianred;

    }
  }
}
</style>